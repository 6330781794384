import {
  FETCH_CREATE_ORDER_REQUEST,
  FETCH_CREATE_ORDER_SUCCESS,
  FETCH_CREATE_ORDER_FAILURE,
  REMOVE_LOADER,
  REMOVE_ERROR,
} from './createOrderTypes';

const intialState = {
  loading: false,
  order: '',
  error: '',
};

export const createOrderReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_CREATE_ORDER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
        error: '',
      };
    case FETCH_CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        order: {},
        error: action.payload,
      };
    case REMOVE_LOADER:
      return { ...state, loading: false, error: action.payload };
    case REMOVE_ERROR:
      return { ...state, error: '' };
    default:
      return { ...state };
  }
};

export default createOrderReducer;
